import * as authorizationService from '@/services/authorization';
import * as userService from '@/services/user';
import { COMPLETE_LOADING, START_LOADING } from '@/store';
import * as interactionService from '@/services/interaction';
import * as types from './constants';

const actions = {
  async loginRequest({ commit }, payload) {
    const { user, redirectUrl } = payload;
    try {
      const loginResponse = await authorizationService.loginRequest(user, redirectUrl);
      commit(types.LOGIN_SUCCESS);
      return loginResponse;
    } catch (e) {
      commit(types.LOGIN_FAILURE);
      throw e;
    }
  },

  async logout() {
    await authorizationService.logoutRequest();
  },

  async getUser({ commit }) {
    const user = await userService.userRequest();
    commit(types.SET_USER, user);
    // a temporary product name map
    // TODO: remove
    user.products.map((product) => {
      if (product.productType === 'BQ_PERFORMANCE_INDIVIDUAL_COACH_CERTIFICATE')
        product.productType = 'BQ_PERFORMANCE_SELF_ASSESSMENT';
      return product;
    });
    return user;
  },

  async createClient({ commit }, payload) {
    const { client, productConfigurationId } = payload;
    try {
      const newClientData = await userService.createClientRequest(client, productConfigurationId);
      commit(types.CREATE_CLIENT_SUCCESS);
      return newClientData;
    } catch (e) {
      commit(types.CREATE_CLIENT_FAILURE);
      throw e;
    }
  },

  async deleteClient({ commit }, payload) {
    const { clientToken, productType } = payload;
    try {
      await userService.deleteClientRequest(clientToken, productType);
      commit(types.DELETE_CLIENT_SUCCESS);
    } catch (e) {
      commit(types.DELETE_CLIENT_FAILURE);
      throw e;
    }
  },

  async changePassword({ commit }, payload) {
    commit(START_LOADING);
    const { currentPassword, password, id } = payload;
    try {
      await userService.changePassword(id, currentPassword, password);
      commit(types.CHANGE_PASSWORD_SUCCESS);
      commit(COMPLETE_LOADING);
    } catch (e) {
      commit(types.CHANGE_PASSWORD_FAILURE);
      commit(COMPLETE_LOADING);
      throw e;
    }
  },

  async updateClient({ commit }, payload) {
    const { client } = payload;
    commit(START_LOADING);
    try {
      await userService.updateClient(client);
      commit(types.UPDATE_CLIENT_SUCCESS);
      commit(COMPLETE_LOADING);
    } catch (e) {
      commit(types.UPDATE_CLIENT_FAILURE);
      commit(COMPLETE_LOADING);
      throw e;
    }
  },

  async updateClientSettings({ commit, dispatch }, payload) {
    commit(START_LOADING);
    try {
      await userService.updateClientSettings(payload).then(() => dispatch('getUser'));
      commit(types.UPDATE_CLIENT_SUCCESS);
      commit(COMPLETE_LOADING);
    } catch (e) {
      commit(types.UPDATE_CLIENT_FAILURE);
      commit(COMPLETE_LOADING);
      throw e;
    }
  },

  async recoverPassword({ commit }, payload) {
    commit(START_LOADING);
    try {
      await userService.recoverPassword(payload);
      commit(COMPLETE_LOADING);
    } catch (e) {
      commit(COMPLETE_LOADING);
      throw e;
    }
  },

  async resetPassword({ commit }, payload) {
    commit(START_LOADING);
    try {
      await userService.resetPassword(payload);
      commit(COMPLETE_LOADING);
    } catch (e) {
      commit(COMPLETE_LOADING);
      throw e;
    }
  },

  async verifyPasswordResetToken({ commit }, payload) {
    commit(START_LOADING);
    try {
      await userService.verifyPasswordResetToken(payload);
      commit(COMPLETE_LOADING);
    } catch (e) {
      commit(COMPLETE_LOADING);
      throw e;
    }
  },

  async fetchCreateUserLimits({ commit }, payload) {
    commit(START_LOADING);
    const { initiatorId } = payload;
    try {
      const interactionLimits = await interactionService.fetchCreateUserLimits(initiatorId);
      commit(types.FETCH_CREATE_USER_LIMITS_SUCCESS, interactionLimits.limit);
      commit(COMPLETE_LOADING);
    } catch (e) {
      commit(types.FETCH_CREATE_USER_LIMITS_FAILURE, e);
      commit(COMPLETE_LOADING);
    }
  },

  async addUserToAnonymizationAllowList({ commit }, payload) {
    commit(START_LOADING);
    const { productConfigurationId, bqUserToken } = payload;

    try {
      await userService.addUserToAnonymizationAllowList(productConfigurationId, bqUserToken);
      commit(COMPLETE_LOADING);
    } catch (e) {
      commit(COMPLETE_LOADING);
    }
  },

  async removeUserFromAnonymizationAllowList({ commit }, payload) {
    commit(START_LOADING);
    const { productConfigurationId, bqUserToken } = payload;

    try {
      await userService.removeUserFromAnonymizationAllowList(productConfigurationId, bqUserToken);
      commit(COMPLETE_LOADING);
    } catch (e) {
      commit(COMPLETE_LOADING);
    }
  },

  setCurrentProduct({ commit }, payload) {
    commit(types.SET_CURRENT_PRODUCT, payload);
  },

  async submitEmail(_, payload) {
    await userService.submitEmail(payload);
  },

  async authenticateWithGoogle({ commit }, payload) {
    const { productType, data } = payload;
    commit(types.SOCIALS_AUTH_LOADING_START);
    const response = await userService.authenticateWithGoogle(productType, data);
    if (response.data && response.data.redirectUrl) {
      commit(types.SOCIALS_AUTH_RESPONSE, response.data.redirectUrl);
      commit(types.SOCIALS_AUTH_LOADING_COMPLETE);
    } else {
      commit(types.SOCIALS_AUTH_LOADING_COMPLETE);
      return response;
    }
  },

  async register(_, payload) {
    await userService.register(payload);
  },

  async submitEmailBQPCertificate(_, payload) {
    await userService.submitEmailBQPCertificate(payload);
  },

  async registerBQPCertificate(_, payload) {
    await userService.registerBQPCertificate(payload);
  },

  async verifyEmailToken(_, payload) {
    return userService.verifyEmailToken(payload.emailVerificationToken);
  },

  async fetchPrivacyPolicyData({ commit }, payload) {
    const { accountId } = payload;
    commit(START_LOADING);
    try {
      const privacyPolicyData = await userService.fetchPrivacyPolicyData(accountId);
      commit(types.FETCH_PRIVACY_POLICY_DATA_SUCCESS, privacyPolicyData);
    } catch (e) {
      commit(types.FETCH_PRIVACY_POLICY_DATA_FAILURE);
    } finally {
      commit(COMPLETE_LOADING);
    }
  },

  async updatePrivacyPolicyData({ commit }, payload) {
    commit(START_LOADING);
    try {
      await userService.updatePrivacyPolicyData(payload);
    } finally {
      commit(COMPLETE_LOADING);
    }
  },
};

export default actions;
