import * as API from '@/services/api';

export async function fetchAvailablePaymentConfigurations(productType) {
  // a temporary product name map
  // TODO: remove
  if (productType === 'BQ_PERFORMANCE_SELF_ASSESSMENT') productType = 'BQ_PERFORMANCE_INDIVIDUAL_COACH_CERTIFICATE';
  const response = await API.get(`/api/payment/configurations?productType=${productType}`);
  return response.data;
}

/**
 * in order for the server-side payment redirect to work we just set the window location
 * as browser navigation request instead of sending usual axios request, as axios will not
 * easily allow server-side redirects (see e.g. https://github.com/axios/axios/issues/932)
 * @param {*} paymentConfigurationToken
 */

export function getPaymentLink(paymentConfigurationToken) {
  return `${window.location.origin}/api/payment/pay/${paymentConfigurationToken}`;
}

export function redirectToPayment(paymentConfigurationToken) {
  const paymentLink = getPaymentLink(paymentConfigurationToken);
  window.open(paymentLink, '_top');
}

export async function unlockInteractionWithCredit(interactionToken) {
  const response = await API.post('/api/credit/unlock', {
    interactionToken,
  });
  return response.data;
}

export async function startBQATrial() {
  const response = await API.get('/api/trial/bq_advisory_wealth');
  return response.data;
}

export async function dismissBQATrial() {
  const response = await API.get('/api/trial/bq_advisory_wealth/dismiss');
  return response.data;
}

export async function resendConfirmationEmail() {
  const response = await API.get('/api/email/verification/resend');
  return response.data;
}

export async function addAdditionalProduct(initiatorId, productType) {
  // a temporary product name map
  // TODO: remove
  if (productType === 'BQ_PERFORMANCE_SELF_ASSESSMENT') productType = 'BQ_PERFORMANCE_INDIVIDUAL_COACH_CERTIFICATE';
  const response = await API.post('/api/add_product', {
    initiatorId,
    productType,
  });
  return response.data;
}
