import * as API from '@/services/api';

export async function fetchBiCategories(productType, localeId) {
  const queryString = `?locale=${localeId}`;
  const response = await API.get(`/api/bi/${productType}/categories${queryString}`);
  return response.data;
}

export async function fetchBiCategoryDetails(productType, categoryKey, group, localeId) {
  let queryString = `?locale=${localeId}`;
  if (group) {
    queryString += `&group=${group}`;
  }
  const response = await API.get(`/api/bi/${productType}/category/${categoryKey}${queryString}`);
  return response.data;
}

export async function fetchBiClientsList(productType, categoryKey, filter, q, group, paginationParams, localeId) {
  let queryString = '';
  if (filter && filter.length > 0) {
    const filterString = filter.map((filterStr) => `filter=${filterStr}`).join('&');
    queryString += filterString;
  }

  if (q) {
    queryString += `&q=${q}`;
  }

  if (group) {
    queryString += `&group=${group}`;
  }

  for (const [key, value] of Object.entries(paginationParams)) {
    queryString += `&${key}=${value}`;
  }

  queryString += `&locale=${localeId}`;

  queryString = queryString ? `?${queryString}` : '';

  const response = await API.get(`/api/bi/${productType}/category/${categoryKey}/clients${queryString}`);
  return response.data;
}

export async function fetchClientProfile(token, repeatedMeasurementId) {
  const response = await API.get(`/api/client/${token}/product/${repeatedMeasurementId}/profile`);
  return response.data;
}

export async function fetchParticipants() {
  const response = await API.get('/api/participants');
  return response.data;
}

export async function fetchOwnParticipant(productType) {
  // a temporary product name map
  // TODO: remove
  if (productType === 'BQ_PERFORMANCE_SELF_ASSESSMENT') productType = 'BQ_PERFORMANCE_INDIVIDUAL_COACH_CERTIFICATE';
  const response = await API.get(`/api/${productType}/participant/me`);
  return response.data;
}

export async function fetchParticipantsForRepeatedMeasurement(repeatedMeasurementId, showOnlyCompleted) {
  const paramsObj = {
    ...(showOnlyCompleted !== undefined && { showOnlyCompleted }),
  };

  const params = new URLSearchParams(paramsObj).toString();

  const response = await API.get(`/api/repeated_measurement/${repeatedMeasurementId}/participants?${params}`);
  return response.data;
}
