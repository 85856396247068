import * as API from '@/services/api';
import { arrayBufferToStr } from '@/utils/download';

export async function fetchBQPReportContent(interactionInstanceToken) {
  const response = await API.get(`/api/report/content/bqp/${interactionInstanceToken}`);
  return response.data;
}

export async function fetchBQAReportContent(interactionInstanceToken, locale) {
  const response = await API.get(`/api/report/content/bqa/${interactionInstanceToken}?locale=${locale}`);
  return response.data;
}

export async function fetchBQARecommendationsReportContent(interactionInstanceToken, locale) {
  const response = await API.get(
    `/api/report/content/bqa/recommendations/${interactionInstanceToken}?locale=${locale}`
  );
  return response.data;
}

export async function downloadReport(interactionInstanceToken, interactionType, locale, format, anonymize) {
  const response = await API.pdfDownload(
    `/api/render/report/${interactionInstanceToken}?&interactionType=${interactionType}&locale=${locale}&pageFormat=${format}&anonymize=${anonymize}`
  );
  return response;
}

export async function downloadReportByReportToken(reportDownloadToken, interactionType, locale, format) {
  try {
    const response = await API.pdfDownload(
      `/api/report/download/${reportDownloadToken}?&interactionType=${interactionType}&locale=${locale}&pageFormat=${format}`
    );
    return response;
  } catch (e) {
    // convert arrayBuffer from PDF download so error is readable
    e.response.data = JSON.parse(arrayBufferToStr(e.response.data));
    throw e;
  }
}

export async function downloadRecommendationsReport(
  interactionInstanceToken,
  interactionType,
  locale,
  format,
  anonymize
) {
  return downloadReport(interactionInstanceToken, interactionType, locale, format, anonymize);
}

export async function getDownloadInfoByReportToken(reportDownloadToken) {
  const response = await API.get(`/api/report/download/${reportDownloadToken}/info`);
  return response;
}

export async function setSampleReportClick() {
  await API.post('/api/certificate/sampleReport');
}
