import { PERMISSION_REALM } from '@/constants/userPermissions';

export const INTERACTION_STATUS = {
  NEW: 'NEW',
  OPENED: 'OPENED',
  FILLED: 'FILLED',
  CALCULATING: 'CALCULATING',
  PREDICTING: 'PREDICTING',
  RESULTS_AVAILABLE: 'RESULTS_AVAILABLE',
  PREDICTION_ERROR: 'PREDICTION_ERROR',
  ERROR: 'ERROR',
  CLOSED: 'CLOSED',
};

export const INTERACTION_LOCKING = {
  LOCKED: 'LOCKED',
  UNLOCKED: 'UNLOCKED',
  TRIAL: 'TRIAL',
};

export const PRODUCT = {
  BQ_ADVISORY_BANK: {
    productType: 'BQ_ADVISORY_BANK',
    name: 'wa_demo_bank',
    icon: 'product_bqa_bank.svg',
    interactionSlug: 'wa_demo_bank',
    translationKey: 'BQUI_MENU_NAV_PRODUCT_BQA_BANK_BUTTON_TEXT',
    fileNameTranslationKey: 'BQUI_FILE_DOWNLOAD_BQA_BANK_PRODUCT_NAME',
    productTranslationKey: 'PRODUCT_BQA',
    realm: PERMISSION_REALM.BQ_ADVISORY_BANK,
  },
  BQ_ADVISORY_WEALTH: {
    productType: 'BQ_ADVISORY_WEALTH',
    name: 'wa_demo',
    icon: 'product_bqa_wealth.svg',
    interactionSlug: 'wa_demo',
    translationKey: 'BQUI_MENU_NAV_PRODUCT_BQA_WEALTH_BUTTON_TEXT',
    fileNameTranslationKey: 'BQUI_FILE_DOWNLOAD_BQA_WEALTH_PRODUCT_NAME',
    productTranslationKey: 'PRODUCT_BQA',
    realm: PERMISSION_REALM.BQ_ADVISORY_WEALTH,
  },
  BQ_RISK: {
    productType: 'BQ_RISK',
    name: 'BQ_Risk',
    icon: 'product_bqr.svg',
    interactionSlug: 'BQ_Risk',
    translationKey: 'BQUI_MENU_NAV_PRODUCT_BQR_BUTTON_TEXT',
    fileNameTranslationKey: 'BQUI_FILE_DOWNLOAD_BQR_PRODUCT_NAME',
    productTranslationKey: 'PRODUCT_BQR',
    realm: PERMISSION_REALM.BQ_RISK,
  },
  BQ_SELECTION: {
    productType: 'BQ_SELECTION',
    name: 'BQS_Meta',
    icon: 'product_bqs.svg',
    interactionSlug: 'BQS_Meta',
    translationKey: 'BQUI_MENU_NAV_PRODUCT_BQS_BUTTON_TEXT',
    fileNameTranslationKey: 'BQUI_FILE_DOWNLOAD_BQS_PRODUCT_NAME',
    productTranslationKey: 'PRODUCT_BQP',
    realm: PERMISSION_REALM.BQ_SELECTION,
  },
  BQ_PERFORMANCE: {
    productType: 'BQ_PERFORMANCE',
    name: 'BQP_Meta',
    icon: 'product_bqp.svg',
    interactionSlug: 'BQS_Meta',
    translationKey: 'BQUI_MENU_NAV_PRODUCT_BQP_BUTTON_TEXT',
    fileNameTranslationKey: 'BQUI_FILE_DOWNLOAD_BQP_PRODUCT_NAME',
    productTranslationKey: 'PRODUCT_BQP',
    realm: PERMISSION_REALM.BQ_PERFORMANCE,
  },
  BQ_PERFORMANCE_SELF_ASSESSMENT: {
    productType: 'BQ_PERFORMANCE_SELF_ASSESSMENT',
    name: 'BQP_Meta',
    icon: 'product_bqp_individual_coach_certificate.svg',
    interactionSlug: 'BQS_Meta',
    translationKey: 'BQUI_MENU_NAV_PRODUCT_BQ_PERFORMANCE_SELF_ASSESSMENT_BUTTON_TEXT',
    fileNameTranslationKey: 'BQUI_FILE_DOWNLOAD_BQ_PERFORMANCE_SELF_ASSESSMENT_PRODUCT_NAME',
    productTranslationKey: 'PRODUCT_BQP_SELF_ASSESSMENT',
    realm: PERMISSION_REALM.BQ_PERFORMANCE_INDIVIDUAL_COACH_CERTIFICATE,
  },

  getProductInteractionMapping(productName) {
    const result = Object.entries(this).find((entry) => entry[1].name === productName);

    if (result !== undefined && result.length === 2) {
      return result[1];
    }

    return { name: 'undefined.product', interaction: 'undefined.interaction' };
  },
};
