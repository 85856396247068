import { PERMISSION_REALM } from '@/constants/userPermissions';

export const CLIENT_GROUP = {
  PROSPECT: 'PROSPECT',
  ESTABLISHED: 'ESTABLISHED',
};

export const ACCOUNT_TYPE = {
  DEMO: 'DEMO',
  INTERNAL: 'INTERNAL',
  ENTERPRISE: 'ENTERPRISE',
  SELF_SERVICE: 'SELF_SERVICE',
};

export const PRODUCT_LEVEL = {
  TRIAL: 'TRIAL',
  BASIC: 'BASIC',
};

export const PRODUCTS = [
  {
    id: 'BQ_ADVISORY_WEALTH',
    productType: 'BQ_ADVISORY_WEALTH',
    name: 'wa_demo',
    icon: 'product_bqa_wealth.svg',
    interactionSlug: 'wa_demo',
    translationKey: 'BQUI_MENU_NAV_PRODUCT_BQA_WEALTH_BUTTON_TEXT',
    fileNameTranslationKey: 'BQUI_FILE_DOWNLOAD_BQA_WEALTH_PRODUCT_NAME',
    productTranslationKey: 'PRODUCT_BQA',
    realm: PERMISSION_REALM.BQ_ADVISORY_WEALTH,
    externalLink: 'BQUI_MENU_NAV_PRODUCT_BQ_ADVISORY_BUTTON_LINK_EXTERNAL',
  },
  {
    id: 'BQ_PERFORMANCE_SELF_ASSESSMENT',
    productType: 'BQ_PERFORMANCE_SELF_ASSESSMENT',
    name: 'BQP_Meta',
    icon: '',
    interactionSlug: 'BQS_Meta',
    translationKey: 'BQUI_MENU_NAV_PRODUCT_BQ_PERFORMANCE_SELF_ASSESSMENT_BUTTON_TEXT',
    fileNameTranslationKey: 'BQUI_FILE_DOWNLOAD_BQ_PERFORMANCE_SELF_ASSESSMENT_PRODUCT_NAME',
    productTranslationKey: 'PRODUCT_BQP_SELF_ASSESSMENT',
    realm: PERMISSION_REALM.BQ_PERFORMANCE_INDIVIDUAL_COACH_CERTIFICATE,
    externalLink: 'BQUI_MENU_NAV_PRODUCT_BQ_PERFORMANCE_SELF_ASSESSMENT_LINK_EXTERNAL',
  },
  {
    id: 'BQ_SELECT_CAMPAIGN',
    productType: 'BQ_SELECT_CAMPAIGN',
    name: 'BQP_Meta',
    icon: '',
    interactionSlug: 'BQS_Meta',
    translationKey: 'BQUI_MENU_NAV_PRODUCT_BQ_SELECT_CAMPAIGN_BUTTON_TEXT',
    fileNameTranslationKey: 'BQUI_FILE_DOWNLOAD_BQ_SELECT_CAMPAIGN_PRODUCT_NAME',
    productTranslationKey: 'PRODUCT_BQ_SELECT_CAMPAIGN',
    realm: PERMISSION_REALM.BQ_SELECT_CAMPAIGN,
    externalLink: 'BQUI_MENU_NAV_PRODUCT_BQ_SELECT_CAMPAIGN_LINK_EXTERNAL',
  },
  {
    id: 'BQ_PERFORMANCE_COACH',
    productType: 'BQ_PERFORMANCE_COACH',
    name: 'BQP_Meta',
    icon: '',
    interactionSlug: 'BQS_Meta',
    translationKey: 'BQUI_MENU_NAV_PRODUCT_BQ_PERFORMANCE_COACH_BUTTON_TEXT',
    fileNameTranslationKey: 'BQUI_FILE_DOWNLOAD_BQ_PERFORMANCE_COACH_PRODUCT_NAME',
    productTranslationKey: 'PRODUCT_BQ_PERFORMANCE_COACH',
    realm: PERMISSION_REALM.BQ_PERFORMANCE_COACH,
    externalLink: 'BQUI_MENU_NAV_PRODUCT_BQ_PERFORMANCE_COACH_LINK_EXTERNAL',
  },
];
